<template>
  <div class="w-full min-h-screen bg-eliteverde">
    <Menu :lang="lang" />
    <div class="bg-gray-50 min-h-screen">
      <div class="mt-14 p-8 text-center font-serif uppercase">
        <h1 class="text-3xl">{{ title[lang] }}</h1>
      </div>
      <div class="container mx-auto">
        <div class="aviso">
          <p>
            El presente aviso de privacidad se establece en cumplimiento con lo
            dispuesto en la Ley Federal de Protección de Datos Personales en
            Posesión de los Particulares (la “Ley de Datos Personales”), por
            favor lea cuidadosamente los términos y condiciones que se precisan
            en el aviso, puesto que cualquier aportación de Datos Personales que
            usted efectúe por cualquier medio, constituye la aceptación expresa
            de estos términos y condiciones, así como la autorización expresa a
            Firma de Abogados Élite, S.C. (ÉLITE) para el tratamiento de sus
            Datos Personales.
          </p>
          <p>
            El presente aviso de privacidad se establece en cumplimiento con lo
            dispuesto en la Ley Federal de Protección de Datos Personales en
            Posesión de los Particulares (la “Ley de Datos Personales”), por
            favor lea cuidadosamente los términos y condiciones que se precisan
            en el aviso, puesto que cualquier aportación de Datos Personales que
            usted efectúe por cualquier medio, constituye la aceptación expresa
            de estos términos y condiciones, así como la autorización expresa a
            Firma de Abogados Élite, S.C. (ÉLITE) para el tratamiento de sus
            Datos Personales. Todos los términos utilizados con mayúscula
            inicial no definidos en el presente aviso tendrán el significado que
            se les atribuye en la Ley de Datos Personales.
          </p>
          <p>
            El presente aviso de privacidad se establece en cumplimiento con lo
            dispuesto en la Ley Federal de Protección de Datos Personales en
            Posesión de los Particulares (la “Ley de Datos Personales”), por
            favor lea cuidadosamente los términos y condiciones que se precisan
            en el aviso, puesto que cualquier aportación de Datos Personales que
            usted efectúe por cualquier medio, constituye la aceptación expresa
            de estos términos y condiciones, así como la autorización expresa a
            Firma de Abogados Élite, S.C. (ÉLITE) para el tratamiento de sus
            Datos Personales.
          </p>
          <h2>Identidad y Domicilio del Responsable</h2>
          <p>
            Firma de Abogados Élite, S.C., con domicilio en Avenida Chapultepec
            1922, Nivel 8, Oficina B8, Fraccionamiento Desarrollos del Pedregal,
            San Luis Potosí, S.L.P., México.
          </p>
          <p>
            El presente aviso de privacidad se establece en cumplimiento con lo
            dispuesto en la Ley Federal de Protección de Datos Personales en
            Posesión de los Particulares (la “Ley de Datos Personales”), por
            favor lea cuidadosamente los términos y condiciones que se precisan
            en el aviso, puesto que cualquier aportación de Datos Personales que
            usted efectúe por cualquier medio, constituye la aceptación expresa
            de estos términos y condiciones, así como la autorización expresa a
            Firma de Abogados Élite, S.C. (ÉLITE) para el tratamiento de sus
            Datos Personales.
          </p>
          <h2>Finalidades del Tratamiento de Datos Personales</h2>
          <p>
            Los datos y/o información que de manera voluntaria nos proporcione o
            haya proporcionado, es confidencial. Las finalidades por las cuales
            ÉLITE recaba Datos Personales son las siguientes: envío de boletines
            de noticias; aplicación de encuestas de satisfacción por la
            prestación de los servicios; envío de información y cotizaciones
            sobre los servicios prestados; envío de invitaciones a eventos;
            respuesta a solicitudes de información; cumplimiento de obligaciones
            contractuales contraídas con nuestros clientes; recepción y
            procesamiento de pagos; generación de comprobantes fiscales; alta de
            cuentas bancarias en la base de datos de ÉLITE; notificaciones
            relacionadas con los servicios prestados; envío de documentación;
            cumplimiento con la legislación aplicable; evaluación de posibles
            candidatos para contratación; administración y auditorías internas;
            evaluación, desarrollo y mejora en la calidad de los servicios;
            generación de información estadística en relación con la cartera de
            clientes y elaboración de estudios internos sobre sus hábitos de
            consumo y aspectos demográficos.
          </p>
          <p>
            El presente aviso de privacidad se establece en cumplimiento con lo
            dispuesto en la Ley Federal de Protección de Datos Personales en
            Posesión de los Particulares (la “Ley de Datos Personales”), por
            favor lea cuidadosamente los términos y condiciones que se precisan
            en el aviso, puesto que cualquier aportación de Datos Personales que
            usted efectúe por cualquier medio, constituye la aceptación expresa
            de estos términos y condiciones, así como la autorización expresa a
            Firma de Abogados Élite, S.C. (ÉLITE) para el tratamiento de sus
            Datos Personales. En general, los Datos Personales que recaba ÉLITE
            de los Titulares son: nombre, nacionalidad, fecha y lugar de
            nacimiento, estado civil, teléfono, domicilio, ocupación o
            profesión, empresa en la cual labora, Registro Federal de
            Contribuyentes, Clave Única del Registro de Población y correo
            electrónico.
          </p>
          <p>
            En caso de que, al momento de recabar Datos Personales, exista una
            finalidad para el Tratamiento de los mismos distinta de las
            señaladas en esta sección, ÉLITE lo comunicará al Titular, con el
            objetivo de que, en caso de ser necesario, manifieste su
            consentimiento con la finalidad que corresponda.
          </p>
          <h2>Medidas de Seguridad con Respecto a Datos Personales</h2>
          <p>
            Es responsabilidad del Titular de los Datos Personales, garantizar
            que los Datos Personales facilitados a ÉLITE sean veraces y
            completos, por lo cual, deberá comunicar a ÉLITE cualquier
            modificación a los mismos, a efecto de que se pueda cumplir con la
            obligación de mantener la información actualizada.
          </p>
          <p>
            La temporalidad del manejo de los Datos Personales del Titular
            dependerá de la relación jurídica que tenga con ÉLITE, así como de
            las obligaciones exigidas por la legislación vigente y las
            autoridades competentes. En todos los casos la información será
            guardada sólo por el tiempo razonable.
          </p>
          <p>
            ÉLITE ha establecido y se compromete a mantener las medidas de
            seguridad administrativas, técnicas y físicas que permitan proteger
            los Datos Personales que recabe contra cualquier daño, pérdida,
            alteración, acceso o tratamiento no autorizado.
          </p>
          <h2>Medios para Ejercer los Derechos ARCO</h2>
          <p>
            Para ejercer los derechos de acceso, rectificación, cancelación u
            oposición (“Derechos ARCO”), de conformidad con lo dispuesto en la
            Ley de Datos Personales, los Titulares podrán presentar una
            solicitud por escrito dirigida a ÉLITE a la siguiente dirección de
            correo electrónico contacto@elite-abogados.mx o directamente al
            domicilio de ÉLITE antes indicado.
          </p>
          <p>
            La solicitud de acceso, rectificación, cancelación u oposición
            deberá contener y acompañar lo siguiente:
          </p>
          <p>
            Nombre del Titular y domicilio u otro medio al que ÉLITE deberá
            enviar la respuesta correspondiente a la solicitud respectiva;
          </p>
          <p>
            Los documentos que acrediten la identidad del Titular o, en su caso,
            la representación legal de la persona que realiza la solicitud en su
            nombre;
          </p>
          <p>
            La descripción clara y precisa de los Datos Personales respecto de
            los cuales el Titular desea ejercer alguno de los derechos de
            acceso, rectificación, cancelación u oposición;
          </p>
          <p>
            En el caso de solicitudes de rectificación de Datos Personales, el
            Titular deberá indicar, además de lo señalado en esta sección, las
            modificaciones a realizarse y aportar la documentación que sustente
            su petición, y;
          </p>
          <p>
            Cualquier otro elemento o documento que facilite la localización de
            los Datos Personales correspondientes.
          </p>
          <p>
            ÉLITE comunicará al Titular la determinación adoptada, en un plazo
            máximo de veinte días hábiles, contados desde la fecha en que se
            recibió la solicitud correspondiente, a efecto de que, si resulta
            procedente, se haga efectiva la misma dentro de los quince días
            hábiles siguientes a la fecha en que se comunique la respuesta.
          </p>
          <p>
            Los plazos antes referidos podrán ser ampliados una sola vez por un
            periodo igual, siempre y cuando así lo justifiquen las
            circunstancias del caso.
          </p>
          <h2>Transferencias de Datos Personales</h2>
          <p>
            Los Datos Personales recabados por ÉLITE no se comparten ni
            transfieren con terceros a excepción de aquellos que fueran
            requeridos por autoridades federales o locales, aquellos respecto de
            los cuales el Titular previamente hubiera manifestado su
            consentimiento o aquellos que el Titular haya proporcionado para que
            sean presentados o transferidos a determinados terceros como parte
            de un trámite, litigio o asunto legal.
          </p>
          <p>
            En caso de ser necesario, ÉLITE comunicará a los Terceros a quienes
            transfiere Datos Personales el presente Aviso de Privacidad y las
            finalidades a las que el Titular sujetó su tratamiento.
          </p>
          <h2>Modificaciones o Cambios al Aviso de Privacidad</h2>
          <p>
            Con el presente Aviso de Privacidad, los Titulares quedan
            debidamente informados de los Datos Personales que se les podrán
            recabar y sus finalidades, aceptando los términos del presente
            documento, elaborados en cumplimiento con la Ley de Datos
            Personales.
          </p>
          <p>
            ÉLITE se reserva el derecho a realizar modificaciones al presente
            Aviso de Privacidad. Cualquier cambio al Aviso de Privacidad será
            publicado en la siguiente página de Internet:
            https://elite-abogados.mx
          </p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>Última actualización: febrero 2022.</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<style scoped>
.aviso p {
  font-size: 16px;
  text-align: justify;
}
.aviso h2 {
  font-size: 22px;
  margin-top: 20px;
  text-transform: uppercase;
}
</style>
<script>
import { LoremIpsum } from "lorem-ipsum";
import Menu from "./Menu.vue";
const lorem = new LoremIpsum({
  sentencesPerParagraph: {
    max: 8,
    min: 4,
  },
  wordsPerSentence: {
    max: 16,
    min: 4,
  },
});
//lorem.generateWords(1);
//lorem.generateSentences(5);
lorem.generateParagraphs(7);
import Api from "@/components/Api";
import axios from "axios";
import Footer from "./Footer";
export default {
  name: "BlogLista",
  components: { Menu, Footer },
  data() {
    return {
      lang: Api.obtenerLocal("lang") || "es",
      url: Api.servidor,
      cdn: Api.cdn + "/",
      empresa: Api.obtenerLocal("bdlg.mx.empresa") || {},
      esMovil: Api.esMovil(),
      title: {
        es: "Aviso de privacidad",
        en: "Notice of privacy (spanish)",
      },
      posts: [
        {
          titulo: {
            en: lorem.generateWords(3),
            es: lorem.generateWords(4),
          },
          contenido: {
            en: lorem.generateParagraphs(5),
            es: lorem.generateParagraphs(5),
          },
          imagen:
            "http://placeimg.com/640/48" +
            Math.floor(Math.random() * 10) +
            "/arch",
        },
        {
          titulo: {
            en: lorem.generateWords(3),
            es: lorem.generateWords(4),
          },
          contenido: {
            en: lorem.generateParagraphs(5),
            es: lorem.generateParagraphs(5),
          },
          imagen:
            "http://placeimg.com/640/48" +
            Math.floor(Math.random() * 10) +
            "/arch",
        },
        {
          titulo: {
            en: lorem.generateWords(3),
            es: lorem.generateWords(4),
          },
          contenido: {
            en: lorem.generateParagraphs(5),
            es: lorem.generateParagraphs(5),
          },
          imagen:
            "http://placeimg.com/640/48" +
            Math.floor(Math.random() * 10) +
            "/arch",
        },
        {
          titulo: {
            en: lorem.generateWords(3),
            es: lorem.generateWords(4),
          },
          contenido: {
            en: lorem.generateParagraphs(5),
            es: lorem.generateParagraphs(5),
          },
          imagen:
            "http://placeimg.com/640/48" +
            Math.floor(Math.random() * 10) +
            "/arch",
        },
        {
          titulo: {
            en: lorem.generateWords(3),
            es: lorem.generateWords(4),
          },
          contenido: {
            en: lorem.generateParagraphs(5),
            es: lorem.generateParagraphs(5),
          },
          imagen:
            "http://placeimg.com/640/48" +
            Math.floor(Math.random() * 10) +
            "/arch",
        },
      ],
    };
  },
  methods: {
    verPost(indice) {
      this.$router.push("/blog/" + indice);
    },
    contenido(p) {
      try {
        return (
          ((p || {}).contenido || "")
            .replace(/\\n/g, "<br />")
            .substring(0, 180) + "..."
        );
      } catch (e) {
        console.error(e, p);
        return "";
      }
    },
  },
  async mounted() {
    console.log("Blog", LoremIpsum);
    try {
      let posts = await axios.post(
        this.url + "/readbypass/postpublic",
        {},
        { headers: { "wst-appkey": "elite-abogados.mx" } }
      );
      this.posts = posts.data.sort((a, b) => (a.fecha < b.fecha ? 1 : -1));
      console.log("posts?", posts.data, this.posts);
    } catch (e) {
      console.error(e);
    }
  },
};
</script>
